<template>
    <div class="promoteGood-container">
        <div class="dy-header">
            <el-radio-group v-model="rankTypeRadio" size="medium" class="rank-type" @change="selectRankType">
                <el-radio-button label="日榜"></el-radio-button>
                <el-radio-button label="周榜"></el-radio-button>
                <el-radio-button label="月榜"></el-radio-button>
            </el-radio-group>
            <el-date-picker size="medium" @change="selectDay" v-if="rankTypeRadio === '日榜'" v-model="dayValue" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
                            :picker-options="pickerOptions" class="rank-date" :clearable="false"></el-date-picker>
            <el-date-picker size="medium" @change="selectWeek" v-if="rankTypeRadio === '周榜'" v-model="weekValue" type="week" format="yyyy 第 WW 周" placeholder="选择周"
                            value-format="yyyy-MM-dd" class="rank-date" :clearable="false"></el-date-picker>
            <el-date-picker size="medium" @change="selectMonth" v-if="rankTypeRadio === '月榜'" v-model="monthValue" type="month" placeholder="选择月"
                            value-format="yyyy-MM-dd" :picker-options="pickerOptions" class="rank-date" :clearable="false"></el-date-picker>
            <el-input size="medium" @change="selectSearch" class="search-input" placeholder="请输入视频内容、热词或关键词" prefix-icon="el-icon-search" v-model="aweme_title"></el-input>
        </div>
        <div class="card-box">
            <div class="card-title">视频销量</div>
            <div class="card-content">
                <div class="card-item">
                    <div class="text">新增视频数量</div>
                    <div class="num">{{videoInfo.video_count_incre}}</div>
                </div>
                <div class="card-item">
                    <div class="text">新增视频点赞</div>
                    <div class="num">{{videoInfo.video_digg_incre}}</div>
                </div>
                <div class="card-item">
                    <div class="text">新增视频销量</div>
                    <div class="num">{{videoInfo.video_volume_incre}}</div>
                </div>
            </div>
        </div>

        <el-table :data="dyHostVideoList" style="width: 100%; min-height: calc(100vh - 589px); margin-top: 10px" size="medium" class="listTable"
                  :header-cell-style="{fontWeight: 'normal', color: '#333'}" :cell-style="{fontSize: '14px',color: '#333'}" @sort-change="sortChange">
            <el-table-column label="视频" min-width="300">
                <template slot-scope="scope">
                    <a :href="scope.row.aweme_url" target="_blank" class="goods-wrapper">
                        <div class="goods-cover">
                            <img :src="scope.row.aweme_cover" alt="">
                        </div>
                        <div class="goods-detail">
                            <div class="goods-title text-overflow" :title="scope.row.aweme_title">视频标题：{{scope.row.aweme_title}}</div>
                            <div class="text-overflow">
                                热词：<span class="hot" v-for="(hotItem, hotIndex) in scope.row.hot_words_info" :key="`hot_words_info_${hotIndex}`">{{hotItem}}</span>
                            </div>
                            <div class="text-overflow">视频时长：{{formatSeconds(scope.row.duration)}}</div>
                        </div>
                    </a>
                </template>
            </el-table-column>
            <el-table-column label="播主" min-width="150" align="center">
                <template slot-scope="scope">
                    <div>{{scope.row.nickname}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="digg_count" label="点赞数" sortable="custom" align="center" min-width="90"></el-table-column>
            <el-table-column prop="comment_count" label="评论数" sortable="custom" align="center" min-width="90"></el-table-column>
            <el-table-column prop="share_count" label="转发数" sortable="custom" align="center" min-width="90"></el-table-column>
            <el-table-column label="操作" align="center" min-width="120" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="toVideoDetail(scope.row)" size="small" circle type="danger"><i class="iconfont">&#xec66;</i></el-button>
                    <a :href="scope.row.aweme_url" target="_blank" style="color: #fff; margin-left: 10px">
                        <el-button type="success" icon="el-icon-caret-right" circle></el-button>
                    </a>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
                v-if="dyHostVideoList.length > 0"
                class="pages-center" background
                :current-page="listPages.currentPageNum"
                :page-size="listPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="listPages.total"
                @current-change="pageCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { chanmamaDataAnalyseDouyinVideoExtend } from '@/utils/apis.js'
    import * as dayjs from "dayjs";
    export default {
        data() {
            return {
                //排行榜类型
                rankTypeRadio: '日榜',
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
                //选择日期
                dayValue: '',
                //选择周
                weekValue: '',
                //选择月
                monthValue: '',
                aweme_title: '',

                dyHostVideoList: [],
                videoInfo: {
                    video_count_incre: 0,
                    video_digg_incre: 0,
                    video_volume_incre: 0,
                },
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                start: dayjs().format('YYYY-MM-DD'), // 日期筛选 日榜 周榜 月榜，开始日期
                end: dayjs().format('YYYY-MM-DD'), // 结束日期

                order: void 0,
                order_type: void 0,
            }
        },
        mounted() {
            this.getDyPromoteVideo()
        },
        methods: {
            // 秒换算为时分秒
            formatSeconds(time){
                let secondTime = parseInt(time)
                let min = 0
                let h = 0
                let result = ''
                if(secondTime > 60){
                    min = parseInt(secondTime / 60)
                    secondTime = parseInt(secondTime % 60)
                    if(min > 60){
                        h = parseInt(min / 60)
                        min = parseInt(min % 60)
                    }
                }
                result = `${h.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${secondTime.toString().padStart(2, '0')}`
                return result
            },
            // 获取列表
            getDyPromoteVideo() {
                let params = {
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum,
                    product_id: this.$route.query.id,
                }
                if (this.start) {
                    params.start = this.start
                }
                if (this.end) {
                    params.end = this.end
                }
                if (this.aweme_title) {
                    params.aweme_title = this.aweme_title
                }
                if (this.order) {
                    params.order = this.order
                }
                if (this.order_type) {
                    params.order_type = this.order_type
                }
                chanmamaDataAnalyseDouyinVideoExtend(params).then(res => {
                    this.dyHostVideoList = res.data.list
                    this.videoInfo = {
                        video_count_incre: res.data.video_count_incre,
                        video_digg_incre: res.data.video_digg_incre,
                        video_volume_incre: res.data.video_volume_incre,
                    }
                    this.listPages.total = res.data.total
                }).catch(err => {})
            },
            // 切换分页
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getDyPromoteVideo()
            },
            // 排序
            sortChange(column, prop, order) {
                this.order = column.prop
                if (column.order === 'ascending') {
                    this.order_type = 'asc'
                } else if (column.order === 'descending') {
                    this.order_type = 'desc'
                } else {
                    this.order_type = void 0
                }
                this.listPages.currentPageNum = 1
                this.getDyPromoteVideo()
            },
            //选择日榜，周榜，月榜
            selectRankType(val) {
                if (val === '日榜') {
                    this.start = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
                    this.end = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
                }
                if (val === '周榜') {
                    this.start = dayjs().startOf('week').format('YYYY-MM-DD')
                    this.end = dayjs().endOf('week').format('YYYY-MM-DD')
                }
                if (val === '月榜') {
                    this.start = dayjs().startOf('month').format('YYYY-MM-DD')
                    this.end = dayjs().endOf('month').format('YYYY-MM-DD')
                }
                this.listPages.currentPageNum = 1
                this.getDyPromoteVideo();
            },
            // 选择日期
            selectDay(val) {
                this.listPages.currentPageNum = 1
                this.start = dayjs(val).subtract(1, 'day').format('YYYY-MM-DD')
                this.end = dayjs(val).subtract(1, 'day').format('YYYY-MM-DD')
                this.getDyPromoteVideo();
            },
            // 选择周榜
            selectWeek(val) {
                this.listPages.currentPageNum = 1
                this.start = dayjs(val).startOf('week').format('YYYY-MM-DD')
                this.end = dayjs(val).endOf('week').format('YYYY-MM-DD')
                this.getDyPromoteVideo();
            },
            // 选择月榜
            selectMonth(val) {
                this.listPages.currentPageNum = 1
                this.start = dayjs(val).startOf('month').format('YYYY-MM-DD')
                this.end = dayjs(val).endOf('month').format('YYYY-MM-DD')
                this.getDyPromoteVideo();
            },
            // 搜索
            selectSearch(val) {
                this.listPages.currentPageNum = 1
                this.aweme_title = val;
                this.getDyPromoteVideo();
            },
            // 去详情页
            toVideoDetail(row) {
                this.$router.push({
                    path: '/student/ds/video/details',
                    query: {
                        aweme_id: row.aweme_id
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .promoteGood-container {
        padding: 20px;
        /*height: calc(100% - 40px);*/
        /*display: flex;*/
        /*flex-direction: column;*/
    }
    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #1E33E3;
        border-color: #1E33E3;
    }
    .dy-header {
        display: flex;
        align-items: center;
        .rank-type {
            margin-right: 20px;
        }
        .rank-date {
            margin-right: 20px;
        }
        .search-input {
            width: 300px;
        }
    }
    // 表格内样式
    .listTable {
        .goods-wrapper {
            display: flex;
            align-items: center;
            .goods-cover {
                width: 80px;
                height: 104px;
                overflow: hidden;
                background: #f3f3f6;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-detail {
                width: 1%;
                flex: 1;
                margin-left: 10px;
                color: #333;
            }
            .hot {
                &:after {
                    content: '、';
                }
                &:last-child {
                    &:after {
                        content: none;
                    }
                }
            }
            &:hover {
                .goods-title {
                    color: #1E33E3;
                }
            }
        }
        .author-name {
            white-space: nowrap;
            color: #2338E6;
        }
    }

    .card-box {
        margin-top: 10px;
        .card-title {
            font-size: 16px;
        }
        .card-content {
            display: flex;
            margin-top: 10px;
            background: #f2f2f2;
            .card-item {
                width: 1%;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 120px;
                .text {
                    font-size: 16px;
                    color: #666;
                }
                .num {
                    margin-top: 10px;
                    font-weight: 700;
                    font-size: 20px;
                }
            }
        }
    }
</style>
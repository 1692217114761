<template>
    <div class="hotGood-container">
        <div class="eCharts-item">
            <div class="item-title">
                <span>抖音销售</span>
                <span class="toggle-day">
                    <span class="day" :class="{current: saleCurrent === 'sale7'}" @click="toggleSale('sale7')">近7天</span>
                    |
                    <span class="day" :class="{current: saleCurrent === 'sale30'}" @click="toggleSale('sale30')">30天</span>
                </span>
            </div>
            <div class="item-content" id="salesDayIncrement"></div>
        </div>
        <div class="eCharts-item">
            <div class="item-title">
                <span>抖音浏览量</span>
                <span class="toggle-day">
                    <span class="day" :class="{current: viewCurrent === 'view7'}" @click="toggleView('view7')">近7天</span>
                    |
                    <span class="day" :class="{current: viewCurrent === 'view30'}" @click="toggleView('view30')">30天</span>
                </span>
            </div>
            <div class="item-content" id="viewsDayIncrement"></div>
        </div>
        <div class="eCharts-item">
            <div class="select-title">
                <span>视频推广播主分析</span>
                <el-select v-model="videoType" placeholder="请选择" size="medium" style="margin-left: 10px" @change="changeVideoType">
                    <el-option v-for="item in videoList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="video-box">
                <div class="video-item">
                    <div class="video-title">播主行业分布</div>
                    <div class="item-content" id="industryData"></div>
                </div>
                <div class="video-item" style="margin-left: 20px">
                    <div class="video-title">播主类型分布</div>
                    <div class="item-content" id="typeData"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { chanmamaDataAnalyseGoodsHeat } from '@/utils/apis.js'

    export default {
        data() {
            return {
                goodsId: Number(this.$route.query.id) || '',
                saleCurrent: 'sale7',
                viewCurrent: 'view7',
                salesDayIncrement: null,
                viewsDayIncrement: null,

                videoType: '1',
                videoList: [
                    {
                        value: '1',
                        label: '销售额'
                    },
                    {
                        value: '2',
                        label: '销量'
                    },
                    {
                        value: '3',
                        label: '播主数'
                    },
                ],
                industryData: null,
                typeData: null,
            }
        },
        mounted() {
            this.salesDayIncrement = this.$echarts.init(document.getElementById('salesDayIncrement'));
            this.viewsDayIncrement = this.$echarts.init(document.getElementById('viewsDayIncrement'));
            this.industryData = this.$echarts.init(document.getElementById('industryData'));
            this.typeData = this.$echarts.init(document.getElementById('typeData'));

            let resizeTimer = null;
            window.onresize = () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    this.salesDayIncrement.resize()
                    this.viewsDayIncrement.resize()
                    this.industryData.resize()
                    this.typeData.resize()
                }, 100)
            }

            this.getHotAnalysis()
        },
        methods: {
            // 获取详情
            getHotAnalysis() {
                let params = {
                    product_id: this.$route.query.id
                }
                chanmamaDataAnalyseGoodsHeat(params).then(res => {
                    if (res.code === 200) {
                        let example1
                        if (this.saleCurrent === 'sale7') {
                            example1 = res.data.week_volume_data
                        } else {
                            example1 = res.data.month_volume_data
                        }
                        let data1 = this.formatData(example1, 'date_str', 'total_volume')
                        this.setEChartsOption(this.salesDayIncrement, data1.xData, data1.yData, '抖音销售')

                        let example2
                        if (this.viewCurrent === 'view7') {
                            example2 = res.data.week_browse_data
                        } else {
                            example2 = res.data.month_browse_data
                        }
                        let data2 = this.formatData(example2, 'date_str', 'pv_count')
                        this.setEChartsOption(this.viewsDayIncrement, data2.xData, data2.yData, '抖音浏览量')

                        let example3, example4, numType3
                        if (this.videoType === '1') {
                            example3 = res.data.anthor_industry_distribution.amount_data
                            example4 = res.data.anthor_type_distribution.amount_data
                            numType3 = 'total_amount'
                        } else if (this.videoType === '2') {
                            example3 = res.data.anthor_industry_distribution.volume_data
                            example4 = res.data.anthor_type_distribution.volume_data
                            numType3 = 'total_volume'
                        } else {
                            example3 = res.data.anthor_industry_distribution.user_data
                            example4 = res.data.anthor_type_distribution.user_data
                            numType3 = 'num'
                        }
                        this.setPieChart(this.industryData, this.formatVideoData(example3, numType3), '播主行业分布')
                        this.setPieChart(this.typeData, this.formatVideoData(example4, numType3), '播主行业分布')
                    } else {
                        this.$message.warning(res.message)
                    }
                }).catch(err => {})
            },

            toggleSale(type) {
                this.saleCurrent = type
                this.getHotAnalysis()
            },
            toggleView(type) {
                this.viewCurrent = type
                this.getHotAnalysis()
            },
            changeVideoType(val) {
                this.videoType = val
                this.getHotAnalysis()
            },

            // 设置折线图
            setEChartsOption(item, xData, yData, title) {
                item.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: xData,
                        axisLabel: {
                            textStyle: {
                                color: '#333'
                            },
                            rotate: 40,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#EAEAEA'
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            textStyle: {
                                color: '#333'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'transparent'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#EAEAEA'
                            }
                        }
                    },
                    grid: {
                        top: 20,
                        left: 60,
                        right: 0,
                        bottom: 60,
                    },
                    series: [{
                        name: title,
                        type: 'line',
                        data: yData,
                        itemStyle: {
                            normal: {
                                color: "#42D7AB",
                            }
                        },
                        areaStyle: {
                            color: '#cff2e6'
                        },
                    }]
                });
            },

            // 处理数据格式
            formatData(listArr, x, y) {
                let data = {
                    xData: [],
                    yData: []
                }
                for (let i = 0; i < listArr.length; i++) {
                    data.xData.push(listArr[i][`${x}`]);
                    data.yData.push(listArr[i][`${y}`]);
                }
                return data
            },
            // 视频推广播主分析数据处理
            formatVideoData(listArr, numType) {
                let arr = []
                for (const key in listArr) {
                    let item = listArr[key]
                    let tmp = {
                        name: item.single_tags,
                        value: Number(item[`${numType}`].toFixed(2)),
                    }
                    arr.push(tmp)
                }
                arr.sort((a, b) => {return b.value - a.value})
                return arr
            },

            // 饼图 eCharts
            setPieChart(item, data, title) {
                item.setOption({
                    tooltip: {
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        // formatter: '{b}：{c}%',
                        formatter: '{a} <br/>{b}: {c} ({d}%)'
                    },
                    grid: {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    },
                    series: [{
                        name: title,
                        type: 'pie',
                        radius: ['50%', '80%'],
                        itemStyle: {
                            borderRadius: 6,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        data: data,
                        color: ['#51c31b', '#65ca30', '#7fe44e', '#85d65e', '#96dd75', '#a7e18c', '#b9e8a4', '#cbeeba', '#dcf5d2', '#edfce9'], //设置对应块的数据
                        label: {
                            color: '#333',
                            // formatter: '{c}%\n{b}',
                            formatter: '{b}',
                        },
                    }]
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .hotGood-container {
        /*height: calc(100% - 40px);*/
        margin: 20px 0;
        /*::v-deep .el-scrollbar__wrap {*/
        /*    overflow-x: hidden;*/
        /*}*/
        .eCharts-item {
            margin-top: 30px;
            padding: 0 20px;
            &:first-child {
                margin-top: 0;
            }
            .item-title {
                font-size: 16px;
                display: flex;
                justify-content: space-between;
                .toggle-day {
                    .day {
                        cursor: pointer;
                        color: #999;
                        &.current, &:hover {
                            color: #42D7AB;
                        }
                    }
                }
            }
            .item-content {
                /*background: #ffa72a;*/
                margin-top: 10px;
                width: 100%;
                height: 340px;
            }
            .select-title {
                font-size: 16px;
            }
            .video-box {
                display: flex;
                margin-top: 20px;
                .video-item {
                    width: calc(50% - 10px);
                    .video-title {
                        font-size: 16px;
                    }
                }
            }
        }
    }
</style>
<template>
    <div class="good-broad-caster">
<!--        @expand-change="exChange"-->
        <el-table :data="goodBroadCasterData"
                  :header-cell-style="{fontWeight: 'normal',color: '#333'}" style="width: 100%; min-height: calc(100vh - 380px);">
            <el-table-column label="播主" width="400">
                <template slot-scope="scope">
                    <div class="goods-detail">
                        <div class="goods-img">
                            <img :src="scope.row.avatar_medium_url" alt="">
                        </div>
                        <div class="goods-info">
                            <span class="user-detail">{{ scope.row.nickname }}</span>
                            <span class="fans-num">粉丝数：{{ scope.row.follower_count }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="总点赞数" sortable align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.total_favorited }}</span>
                </template>
            </el-table-column>
            <el-table-column label="总分享数" sortable align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.share_count }}</span>
                </template>
            </el-table-column>
            <el-table-column label="总评论数" sortable align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.comment_count }}</span>
                </template>
            </el-table-column>
            <el-table-column label="作品发布时间" align="center" min-width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.create_time }}</span>
                </template>
            </el-table-column>
<!--            <el-table-column label="详情" type="expand" prop="broadVideoList">-->
<!--                <template slot-scope="scope">-->
<!--                    <el-table :data="scope.row.broadVideoList" :header-cell-style="{fontWeight: 'normal',color: '#333'}">-->
<!--                        <el-table-column label="视频">-->
<!--                            <template slot-scope="scope">-->
<!--                                <a :href="scope.row.share_url" target="_blank"  class="goods-video-detail">-->
<!--                                    <div class="goods-img">-->
<!--                                        <img :src="scope.row.video_cover_url" alt="">-->
<!--                                    </div>-->
<!--                                    <div class="goods-info">-->
<!--                                        <div class="goods-link" :title="scope.row.desc">-->
<!--                                            {{ scope.row.desc }}-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </a>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column label="点赞" align="center" width="70">-->
<!--                            <template slot-scope="scope">-->
<!--                                <span>{{ scope.row.digg_count }}</span>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column label="分享" align="center" width="70">-->
<!--                            <template slot-scope="scope">-->
<!--                                <span>{{ scope.row.share_count }}</span>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column label="评论" align="center" width="70">-->
<!--                            <template slot-scope="scope">-->
<!--                                <span>{{ scope.row.comment_count }}</span>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column label="发布时间" align="center" width="150">-->
<!--                            <template slot-scope="scope">-->
<!--                                <span>{{ scope.row.create_time }}</span>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column label="操作" align="center" width="150">-->
<!--                            <template slot-scope="scope">-->
<!--                                <el-button type="primary" circle size="small" @click="toVideoDetail(scope.row)">-->
<!--                                    <i class="iconfont">&#xe6a1;</i>-->
<!--                                </el-button>-->
<!--                                <a :href="scope.row.share_url" target="_blank" style="color: #fff; margin-left: 10px">-->
<!--                                    <el-button type="danger" circle size="small">-->
<!--                                        <i class="iconfont">&#xe63a;</i>-->
<!--                                    </el-button>-->
<!--                                </a>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                    </el-table>-->
<!--                </template>-->
<!--            </el-table-column>-->
        </el-table>
<!--        <el-pagination-->
<!--                class="pages-center" background-->
<!--                :current-page="dyGoodBroadCasterPages.currentPageNum"-->
<!--                :page-size="dyGoodBroadCasterPages.eachPageNum"-->
<!--                layout="prev, pager, next, jumper"-->
<!--                :total="dyGoodBroadCasterPages.total"-->
<!--                @current-change="dyGoodBroadCasterCurrentChange">-->
<!--        </el-pagination>-->
    </div>
</template>

<script>
    import _ from "underscore";
    export default {
        name: "GoodBroadCaster",
        data() {
            return {
                goodsId: Number(this.$route.query.id) || '',
                goodBroadCasterData: [],
                //分页
                // dyGoodBroadCasterPages: {
                //     currentPageNum: 1,
                //     eachPageNum: 10,
                //     total: 0
                // }
            }
        },
        mounted() {
          this.getMainAnalysisList();
        },
        methods: {
            //分页
            // dyGoodBroadCasterCurrentChange() {
            //
            // }
            getMainAnalysisList() {
              this.$httpStudent.axiosGetBy(this.$api.getMainAnalysis, {promotion_id: this.$route.query.promotionId}, (res) => {
                  if (res.code === 200) {
                      _.each(res.data, (item) => {
                          item.broadVideoList = [];
                          this.goodBroadCasterData.push(item);
                      });
                  }
              })
            },
            // exChange(row) {
            //     this.$httpStudent.axiosGetBy(this.$api.getMainAnalysisDetail, {userid: row.user_id}, (res) => {
            //         if (res.code === 200) {
            //             row.broadVideoList = res.data;
            //         }
            //     })
            // },
            toVideoDetail(row) {
                this.$router.push({path: '/student/ds/video/details', query: {aweme_id: row.awemeid}})
            }
        }
    }
</script>

<style scoped lang="scss">
    .good-broad-caster {
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        .goods-detail {
            display: flex;
            align-items: center;
            .goods-img {
                width: 34px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-info {
                display: flex;
                flex-direction: column;
                .fans-num {
                    font-size: 14px;
                    color: #999999;
                }
            }
        }
        .goods-video-detail {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            color: #333;
            &:hover {
                color: #1E33E3;
            }
            .goods-img {
                width: 64px;
                height: 89px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-info {
                width: calc(100% - 74px);
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                a {
                    color: #1A1A1A;
                }
            }
        }
    }
    .pages-center {
        width: 100%;
        flex: 1 1 0%;
        height: calc(100% - 52px);
    }
</style>
<template>
    <div class="goodDetail-container">
        <div class="goodDetail-left">
            <div class="goodDetail-intro">
                <div class="good-cover">
                    <img :src="goodsInfo.image" alt="">
                </div>
                <div class="good-detail">
                    <div class="d-left">
                        <div class="title text-overflow-2">{{goodsInfo.title}}</div>
                        <p class="line">
                            <span class="tag">
                                30天浏览量：
                                <span>{{goodsInfo.count ? goodsInfo.count : 0}}</span>
                            </span>
                            <span class="tag">
                                30天销量：
                                <span>{{goodsInfo.order_count ? goodsInfo.order_count : 0}}</span>
                            </span>
                        </p>
                        <p class="line">
                            <span class="tag">
                                关联视频数：
                                <span>{{goodsInfo.video_count ? goodsInfo.video_count : 0}}</span>
                            </span>
                            <span class="tag">
                                关联账号数：
                                <span>{{goodsInfo.author_count ? goodsInfo.author_count : 0}}</span>
                            </span>
                        </p>
                        <p class="line">
                            <span class="tag">
                                30天商品转化率：
                                <span>{{goodsInfo.month_conversion_rate ? (Number(goodsInfo.month_conversion_rate) * 100).toFixed(2) : 0}}%</span>
                            </span>
                        </p>
                    </div>
                    <div class="d-right">
                        <strong class="price-now">&yen;{{goodsInfo.final_price ? goodsInfo.final_price : '无'}}</strong>
                        <span class="price-old">原价: {{goodsInfo.max_price ? goodsInfo.max_price : '无'}}</span>
                    </div>
                    <div class="update-data">
                        <div class="button" @click="updateGoodDetail">
                            <i class="iconfont">&#xe655;</i>数据更新
                        </div>
                        <div class="text">数据更新时间：2021-08-13 11:50</div>
                    </div>
                </div>
            </div>
            <el-tabs v-model="goodDetailTabs" type="card" class="goodDetail-tabs">
                <el-tab-pane label="商品热度分析" name="hot" :lazy="true">
                    <DYGoodHot />
                </el-tab-pane>
                <el-tab-pane label="抖音视频推广" name="promote">
                    <DYGoodPromote />
                </el-tab-pane>
                <el-tab-pane label="带货播主" name="broadCaster">
                    <DYGoodBroadCaster />
                </el-tab-pane>
                <el-tab-pane label="商品观众画像" name="audience" :lazy="true">
                    <DYGoodAudience />
                </el-tab-pane>
            </el-tabs>
        </div>
        <!--右侧帮助中心-->
<!--        <RightHelpList></RightHelpList>-->
    </div>
</template>

<script>
    import DYGoodHot from '@/components/dyGoodsDetail/DYGoodHot.vue'
    import DYGoodPromote from '@/components/dyGoodsDetail/DYGoodPromote.vue'
    import DYGoodBroadCaster from '@/components/dyGoodsDetail/DYGoodBroadCaster.vue'
    import DYGoodAudience from '@/components/dyGoodsDetail/DYGoodAudience.vue'
    import RightHelpList from '@/components/helpCenter/RightHelpList.vue'

    import { chanmamaDataAnalyseGetGoodsHead, chanmamaDataAnalyseReviewGoodsHead } from '@/utils/apis.js'

    export default {
        data() {
            return {
                goodDetailTabs: 'promote',
                // detailType: this.$route.query.type || 'hot',
                // goodsId: Number(this.$route.query.id) || '',
                goodsInfo: {}
            }
        },
        components: {
            DYGoodHot,
            DYGoodPromote,
            DYGoodBroadCaster,
            DYGoodAudience,
            RightHelpList
        },
        mounted() {
            // this.goodDetailTabs = this.detailType
            this.getOneGoodsInfo()
        },
        methods: {
            // 获取商品详情
            getOneGoodsInfo() {
                let params = {
                    product_id: this.$route.query.id
                }
                chanmamaDataAnalyseGetGoodsHead(params).then(res => {
                    if (res.code === 200) {
                        this.goodsInfo = res.data
                    } else {
                        this.$message.warning(res.message)
                    }
                }).catch(err => {})
            },
            // 数据更新
            updateGoodDetail() {
                let params = {
                    product_id: this.$route.query.id
                }
                chanmamaDataAnalyseReviewGoodsHead(params).then(res => {
                    if (res.code === 200) {
                        this.getOneGoodsInfo()
                        this.$message.success(res.message)
                    } else {
                        this.$message.warning(res.message)
                    }
                }).catch(err => {})
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep p {
        margin: 0;
    }
    .goodDetail-container {
        height: 100%;
        display: flex;
        .goodDetail-left {
            width: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            .goodDetail-intro {
                display: flex;
                background: #fff;
                padding: 20px;
                .good-cover {
                    width: 130px;
                    height: 130px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .good-detail {
                    width: 1%;
                    flex: 1;
                    margin-left: 20px;
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    .d-left {
                        width: 1%;
                        flex: 1;
                        .title {
                            font-size: 16px;
                            margin-bottom: 20px;
                            color: #333;
                            &:hover {
                                color: #1E33E3;
                            }
                        }
                        .line {
                            margin-top: 10px;
                            .tag {
                                display: inline-block;
                                color: #999;
                                min-width: 40%;
                                margin-right: 2%;
                            }
                        }
                    }
                    .d-right {
                        margin-left: 100px;
                        .price-now {
                            color: #FF0101;
                            font-size: 18px;
                        }
                        .price-old {
                            color: #666;
                            margin-left: 24px;
                            text-decoration: line-through;
                        }
                    }
                    .update-data {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        text-align: right;
                        .button {
                            margin-bottom: 5px;
                            cursor: pointer;
                            .iconfont {
                                margin-right: 4px;
                            }
                        }
                        .text {
                            color: #999;
                        }
                    }
                }
            }
            .goodDetail-tabs {
                height: 1%;
                flex: 1;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                ::v-deep .el-tabs__header {
                    margin-bottom: 0;
                    border-bottom: none;
                    .el-tabs__nav {
                        border-color: transparent;
                        .el-tabs__item {
                            border-color: transparent;
                            height: 50px;
                            line-height: 50px;
                            border-top-left-radius: 6px;
                            border-top-right-radius: 6px;
                            font-size: 16px;
                            color: #858EA6;
                            &.is-active {
                                color: #0824CE;
                                background: #fff;
                            }
                            &:hover {
                                color: #0824CE;
                            }
                        }
                    }
                }
                ::v-deep .el-tabs__content {
                    height: 1%;
                    flex: 1;
                    background: #fff;
                    .el-tab-pane {
                        height: 100%;
                    }
                }
            }
        }
    }
</style>